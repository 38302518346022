import React from "react";
import styled from "@emotion/styled";
import MascotImage from "@ecologi/assets/icons/mascot.svg";
import MascotShadowImage from "@ecologi/assets/icons/mascot-shadow.svg";

import { Button as ButtonBase } from "../Button";
import { Wrapper } from "../Wrapper";
import { Typography as TypographyBase } from "../Typography";
import { theme } from "../theme";

interface CTAButton {
  text: string;
  link?: string;
  onClick?: ((e: React.MouseEvent) => void) | undefined;
}

export enum MascotCTAColourWays {
  FOREST = "FOREST",
  SNOWDROP = "SNOWDROP",
  MUSHROOM = "MUSHROOM",
  DARK_BLUE = "DARK_BLUE",
}

const MascotCTAColourWayMapper = {
  [MascotCTAColourWays.FOREST]: {
    bg: theme.colors.forest,
    color: theme.colors.snowdrop,
  },
  [MascotCTAColourWays.SNOWDROP]: {
    bg: theme.colors.snowdrop,
    color: theme.colors.night,
  },
  [MascotCTAColourWays.MUSHROOM]: {
    bg: theme.colors.mushroom,
    color: theme.colors.night,
  },
  [MascotCTAColourWays.DARK_BLUE]: {
    bg: theme.colors.darkBlue,
    color: theme.colors.snowdrop,
  },
};

type MascotColourWayProps = {
  colourWay: MascotCTAColourWays;
  isLanding?: boolean;
};

export type MascotCTAProps = {
  title: string;
  subtitle: string;
  buttons: CTAButton[];
  hideMascot?: boolean;
  isLanding?: boolean;
} & MascotColourWayProps;

const Section = styled.div<MascotColourWayProps>`
  background: ${(props) =>
    MascotCTAColourWayMapper[props.colourWay]?.bg || props.theme.colors.forest};
  overflow: hidden; // for mascot
  padding: ${(props) => (props.isLanding ? "50px 0 52px 0" : "250px 0 52px 0")};
  position: relative; // for mascot
  text-align: center;

  @media ${(props) => props.theme.bp.tabletUp} {
    padding: 64px 0 52px;
  }
`;

const Title = styled(TypographyBase)<MascotColourWayProps>`
  color: ${(props) =>
    MascotCTAColourWayMapper[props.colourWay]?.color ||
    props.theme.colors.snowdrop};
  margin-bottom: 8px;

  @media ${(props) => props.theme.bp.tabletUp} {
    margin-left: auto;
    margin-right: auto;
    max-width: 420px;
  }

  @media only screen and (min-width: 860px) {
    max-width: 480px;
  }

  @media only screen and (min-width: 1000px) {
    max-width: 570px;
  }
`;

const Subtitle = styled(Title)`
  margin-bottom: 25px;

  @media ${(props) => props.theme.bp.tabletUp} {
    margin-left: auto;
    margin-right: auto;
    max-width: 380px;
  }

  // Ugly but we gotta dodge the mascot
  @media only screen and (min-width: 800px) {
    max-width: 400px;
  }

  @media only screen and (min-width: 860px) {
    max-width: 480px;
  }

  @media only screen and (min-width: 1000px) {
    max-width: 570px;
  }

  @media ${(props) => props.theme.bp.desktopUp} {
    max-width: 540px;
  }
`;

const Button = styled(ButtonBase)`
  margin: 0 12px 12px;

  @media ${(props) => props.theme.bp.tabletUp} {
    margin: 0 12px;
  }
`;

const Mascot = styled(MascotImage)`
  left: 50%;
  position: absolute;
  top: 0;
  transform: translateX(-50%);

  @media ${(props) => props.theme.bp.tabletUp} {
    left: 10px;
    transform: none;
  }

  @media ${(props) => props.theme.bp.desktopUp} {
    left: 48%;
    transform: translateX(-440px);
  }
`;

// Needs to be a separate image as it's hidden on mobile
const MascotShadow = styled(MascotShadowImage)`
  display: none;

  @media ${(props) => props.theme.bp.tabletUp} {
    display: block;
    position: absolute;
    bottom: 10px;
    left: 10px;
  }

  @media ${(props) => props.theme.bp.desktopUp} {
    left: 48%;
    transform: translateX(-440px);
  }
`;

export const MascotCTA = ({
  title,
  subtitle,
  buttons,
  colourWay,
  hideMascot,
  isLanding,
}: MascotCTAProps) => {
  return (
    <Section colourWay={colourWay} isLanding={isLanding}>
      {!hideMascot && <Mascot />}
      {!hideMascot && <MascotShadow />}
      <Wrapper>
        <Title use="sectionTitle" as="h3" colourWay={colourWay}>
          {title}
        </Title>
        <Subtitle use="subtitle2" as="h4" colourWay={colourWay}>
          {subtitle}
        </Subtitle>
        {buttons.map((button, idx) => (
          <Button
            text={button.text}
            href={button.link}
            key={idx}
            primary
            onClick={button?.onClick}
          />
        ))}
      </Wrapper>
    </Section>
  );
};
